import { Flex, Image, Text } from "@chakra-ui/react";
import recomendacaoFacial from "assets/images/facial-recomendacao.png";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import OutlineButton from "ui/components/Button/OutlineButton";
import InputFile from "ui/components/Input/InputFile";
import ModalCamera from "ui/components/ModalCamera";

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

function FormFacial({
  file,
  setFile,
}: {
  file: string | null;
  setFile: (f: string | null) => any;
}) {
  const [modalCameraOpen, setModalCameraOpen] = useState(false);

  return (
    <>
      <Text color={colors.black700} my={1} textAlign="center">
        Cadastrar facial
      </Text>

      <Flex
        gap={2}
        my={2}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <InputFile
          showIcon={false}
          accept="image/*"
          showPreview={false}
          label="Buscar do dispositivo"
          value={null}
          onChange={async (f) => {
            if (f) {
              const base64 = await getBase64(f);
              setFile(base64);
            }
          }}
        />

        <OutlineButton onClick={() => setModalCameraOpen(true)}>
          Abrir câmera
        </OutlineButton>
      </Flex>

      {file ? (
        <>
          <Flex flex={1} gap={2} justifyContent="center" alignItems="center">
            <Image h="28dvh" w="100%" objectFit="contain" src={file} mb={2} />
          </Flex>
        </>
      ) : null}

      {modalCameraOpen ? (
        <ModalCamera
          visible={modalCameraOpen}
          onClose={() => setModalCameraOpen(false)}
          onSubmit={(uri) => setFile(uri)}
        />
      ) : null}

      {!file ? (
        <>
          <Text color={colors.black600} my={4} textAlign="center" fontSize={12}>
            Recomendação para a foto
          </Text>

          <Image
            w="100%"
            height="auto"
            src={recomendacaoFacial}
            alt="Recomendação p/ Facial"
            mb={4}
          />
        </>
      ) : null}
    </>
  );
}

export default FormFacial;
