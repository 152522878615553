import { useMutation } from "react-query";
import axios from "infra/http";

function useVerificarImagemPortaria(portariaId?: string) {
  return useMutation((form: { documento: string }) =>
    axios.post<{ existeFoto: boolean | null; enviarFoto: boolean }>(
      `/v1/shared/portaria/${portariaId}/verificar-foto`,
      {
        ...form,
      }
    )
  );
}

export default useVerificarImagemPortaria;
