import { useQuery } from "react-query";
import axios from "infra/http";

function useNomePessoaAutorizacao(portariaId: string) {
  return useQuery(
    [`/v1/shared/portaria/${portariaId}/nome`],
    async () => {
      const res = await axios.get<{ nome: string }>(
        `/v1/shared/portaria/${portariaId}/nome`
      );

      return res.data;
    },
    {
      enabled: !!portariaId,
    }
  );
}

export default useNomePessoaAutorizacao;
