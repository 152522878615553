import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import bgLogin from "assets/images/background-login.svg";
import logo from "assets/images/logo-horizontal.png";
import useCadastrarFacialPortaria from "core/features/portaria/useCadastrarFacialPortaria";
import colors from "core/resources/theme/colors";
import { showSuccess } from "infra/helpers/alerta";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import PrimaryButton from "ui/components/Button/PrimaryButton";
import CardDefault from "ui/components/Cards/CardDefault";
import FormFacial from "ui/components/FormFacial";
import Field from "ui/components/Input/Field";
import InputCpfCnpj from "ui/components/Input/InputCpfCnpj";

function CadastrarFacialPortariaPage() {
  const route = useRouteMatch<{ portariaId: string }>();

  const { control, watch } = useForm<{
    nome: string;
    documento: string;
  }>({
    defaultValues: {
      nome: "",
      documento: "",
    },
    reValidateMode: "onBlur",
  });

  const form = watch();

  const [file, setFile] = useState<string | null>(null);

  const { mutate, isLoading } = useCadastrarFacialPortaria(
    route?.params?.portariaId
  );

  const onSubmit = () => {
    if (!file || !form.nome || !form.documento) return;

    const [, imageString] = file.split(",");

    mutate(
      { base64Image: imageString, nome: form.nome, documento: form.documento },
      {
        onSuccess: () => {
          showSuccess("Cadastro atualizado!");
        },
      }
    );
  };

  if (!route.params.portariaId) {
    return (
      <Flex
        backgroundImage={bgLogin}
        h="100dvh"
        bgSize="cover"
        bgRepeat="no-repeat"
      >
        <Container
          maxW="sm"
          alignItems="center"
          pt={4}
          display="flex"
          flexDir="column"
        >
          <Image w="100%" height="auto" src={logo} alt="Logo Octopus" mb={4} />

          <Text fontWeight="bold" fontSize={20} textAlign="center" color="red">
            Link inválido!
          </Text>
          <Text fontWeight="bold" fontSize={20} textAlign="center" color="red">
            Peça um novo para quem autorizou a portaria.
          </Text>
        </Container>
      </Flex>
    );
  }

  return (
    <Flex
      backgroundImage={bgLogin}
      minH="100dvh"
      h="fit-content"
      bgSize="cover"
      bgRepeat="no-repeat"
    >
      <Container
        w="container.lg"
        justifyContent="center"
        pt={4}
        minH="100dvh"
        display="flex"
        flexDir="row"
      >
        <CardDefault
          rounded={8}
          px={4}
          py={2}
          minH="90dvh"
          h="fit-content"
          w="100%"
          maxW="900px"
          alignItems="center"
          display="flex"
          flexDir="column"
          bg="rgba(255,255,255,0.5)"
          cursor="default"
        >
          <>
            <Image
              position="relative"
              height="60px"
              w="auto"
              src={logo}
              alt="Logo Octopus"
            />

            <Text color={colors.black700} my={4} textAlign="center">
              Completar cadastro de portaria
            </Text>

            <Box mb={2} w="100%">
              <Field
                name="nome"
                control={control}
                rules={{ required: true }}
                label="Nome"
              />
            </Box>
            <Box mb={2} w="100%">
              <Field
                name="documento"
                control={control}
                rules={{ required: true }}
                label="CPF/CNPJ"
                render={({ field, isInvalid }) => (
                  <InputCpfCnpj {...field} isInvalid={isInvalid} />
                )}
              />
            </Box>

            <FormFacial file={file} setFile={setFile} />

            {file ? (
              <PrimaryButton
                onClick={onSubmit}
                w="100%"
                isLoading={isLoading}
                disabled={!form.nome || !form.documento || isLoading}
              >
                Salvar
              </PrimaryButton>
            ) : null}
          </>
        </CardDefault>
      </Container>
    </Flex>
  );
}

export default CadastrarFacialPortariaPage;
